import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c(VApp,{attrs:{"id":"inspire"}},[_c(VCard,[_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","search":_vm.search,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mappings,"items-per-page":-1,"search":_vm.search},scopedSlots:_vm._u([{key:"item.mapping_set_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("" + (item.mapping_set_id))}},[_vm._v(" "+_vm._s(item.mapping_set_id)+" ")])]}},{key:"item.mapping_set_group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mapping_set_group)+" ")]}},{key:"item.registry_title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.registry_title)+" ")]}},{key:"item.license",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("" + (item.license))}},[_vm._v(" "+_vm._s(item.license)+" ")])]}},{key:"item.mapping_provider",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("" + (item.mapping_provider))}},[_vm._v(" "+_vm._s(item.mapping_provider)+" ")])]}},{key:"item.mapping_set_description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mapping_set_description)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }